<template>
<div class="colors">
  <div class="colors__item" v-for="color in colors" :key="color">
    <input
      type="radio"
      :value="color"
      :id="color"
      :checked="value === color"
      @change="$emit('change', $event.target.value)"
      name="color"
    >
    <label :for="color" :style="{backgroundColor: color}"></label>
  </div>
</div>
</template>

<script>
import {colorsMap} from "@/components/tags/tagAdd";

export default {
  name: 'TagColors',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: String
  },
  data() {
    return {
      colors: colorsMap
    }
  }
}
</script>