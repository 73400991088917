import {randString} from "@/helpers/random";

const tags = {
  state:() => ({
    list: []
  }),
  mutations: {
    deleteTag(state, hash) {
      state.list = state.list.filter(item => item.hash !== hash);
    },
    addTag(state, data) {
      const tag = ({
        hash: randString(),
        title: data.title,
        color: data.color
      });

      state.list.push(tag);
    },
    addTags(state, tags) {
      tags.map(item => item.hash = randString());
      state.list = tags;
    },
    sortTag(state, {donor, recipient}) {
      const list = [...state.list];
      const donorIndex = list.findIndex(item => item.hash === donor);
      const recipientIndex = list.findIndex(item => item.hash === recipient);
      const recipientValue = list[recipientIndex];

      list[recipientIndex] = list[donorIndex];
      list[donorIndex] = recipientValue;

      state.list = list;
    }
  }
}

export default tags;