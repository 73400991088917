<template>
  <main class="container">
    <div class="row justify-content-center">
      <b-form @submit.prevent="onSubmit" v-if="show" class="col-10">
        <p class="h3 mb-1">Редактирование: {{ model.name }}</p>
        <hr>

        <b-form-group
          id="input-group-1"
          label="Заголовок (title):"
          label-for="title"
        >
          <b-form-input
            id="title"
            v-model="meta.title.value"
            type="text"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-1"
          label="Идентификатор страницы:"
          label-for="title"
        >
          <b-form-input
            id="title"
            v-model="model.alias"
            type="text"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Описание (description):"
          label-for="description"
        >
          <b-form-textarea
            id="description"
            v-model="meta.description.value"
            type="text"
            rows="4"
            required
          ></b-form-textarea>
        </b-form-group>

        <div class="row mt-1">
          <div class="col-md-6">
            <b-form-group
              label="Ключивые слова (keywords):"
              label-for="keywords"
            >
              <b-form-input
                id="keywords"
                v-model="meta.keywords.value"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Канонический адрес (canonical):"
              label-for="canonical"
            >
              <b-form-input
                id="canonical"
                v-model="meta.canonical.value"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <b-form-group label="noIndex:" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="robots"
            v-model="meta.robots.value"
            :options="robots"
            :aria-describedby="ariaDescribedby"
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group label="Статус:" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="insidemenu"
            v-model="model.props.not_publish"
            :options="statusOptions"
            :aria-describedby="ariaDescribedby"
          ></b-form-radio-group>
        </b-form-group>

        <b-button type="submit" variant="primary" >Сохранить</b-button>
        <div class="clearfix mb-5"></div>
      </b-form>

      <div v-else class="text-center"><img src="/img/loading.gif" alt="загрузка"></div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'courseView',
  metaInfo: {
    title: 'Редактировать | Хабовые страницы',
  },

  data() {
    return {
      breadcrumb: [
        {
          html: '<i class="fa fa-home" aria-hidden="true"></i>',
          to: {name: 'profile'}
        },
        {
          text: 'Хабовые страницы',
          to: '/hub-pages'
        },
        {
          text: 'Редактирование'
        }
      ],
      robots: [
        { text: 'не требуется', value: '' },
        { text: 'noindex', value: 'noindex' },
        { text: 'noindex, nofollow', value: 'noindex, nofollow' }
      ],
      statusOptions: [
        { text: 'Не опубликовано', value: '1' },
        { text: 'Опубликовано', value: '0' }
      ],
      show: false,
      model: null,
      meta: null,
    }
  },
  methods: {
    onSubmit() {
      this.show = false;

      const options = {
        params: {
          model: this.model,
          meta: this.meta,
        }
      };

      this.$http.post('/akbiz/hubs/update', options)
        .then((response) => {
          this.model = response.data.model;

          this.show = true;
          this.$store.commit('addToast', {message: 'Данные сохранены'});
        });
    },
    getData() {
      const option = {params: {alias: this.$route.params.id}}

      this.$http.get('/akbiz/hubs/view', option)
        .then((response) => {
          this.show = true;
          this.model = response.data.model;
          this.meta = response.data.meta;
        });
    },
  },
  mounted() {
    this.getData();
    this.$emit('breadcrumb', this.breadcrumb);
  },
}
</script>