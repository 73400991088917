import Vue from 'vue';
import Router from 'vue-router';

import Profile from './views/profile.vue';
import LoginPage from './views/login.vue';
import Robots from './views/robots.vue';
import Sitemap from './views/sitemap.vue';
import Page404 from './views/page404.vue';
import AboutIndex from './views/about/index.vue';
import AboutView from './views/about/view.vue';
import ArticleIndex from './views/article/index.vue';
import ArticleView from './views/article/view.vue';
import CourseIndex from './views/course/index.vue';
import CourseView from './views/course/view.vue';
import BlockEdit from './views/course/blockEdit.vue';
import HubPagesIndex from './views/hub_pages/index.vue';
import HubPagesView from './views/hub_pages/view.vue';

Vue.use(Router);

let router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'profile',
            component: Profile,
            meta: {
                auth: true
            }
        },
        {
            path: '/course',
            name: 'courseIndex',
            component: CourseIndex,
            meta: {
                auth: true
            }
        },
        {
            path: '/course/edit/:id',
            name: 'courseView',
            component: CourseView,
            meta: {
                auth: true
            }
        },
        {
            path: '/block/edit/:id',
            name: 'blockEdit',
            component: BlockEdit,
            meta: {
                auth: true
            }
        },
        {
            path: '/about',
            name: 'aboutIndex',
            component: AboutIndex,
            meta: {
                auth: true
            }
        },
        {
            path: '/about/edit/:alias',
            name: 'aboutView',
            component: AboutView,
            meta: {
                auth: true
            }
        },
        {
            path: '/article',
            name: 'articleIndex',
            component: ArticleIndex,
            meta: {
                auth: true
            }
        },
        {
            path: '/article/edit/:alias',
            name: 'articleView',
            component: ArticleView,
            meta: {
                auth: true
            }
        },
        {
            path: '/hub-pages',
            name: 'hubPages',
            component: HubPagesIndex,
            meta: {
                auth: true
            }
        },
        {
            path: '/hub-pages/edit/:id',
            name: 'hubPagesEdit',
            component: HubPagesView,
            meta: {
                auth: true
            }
        },
        {
            path: '/robots',
            name: 'robots',
            component: Robots,
            meta: {
                auth: true
            }
        },
        {
            path: '/sitemap',
            name: 'sitemap',
            component: Sitemap,
            meta: {
                auth: true
            }
        },
        {
            path: '/login',
            name: 'login',
            component: LoginPage
        },
        {
            path: '*',
            name: 'Page404',
            component: Page404
        }
    ],
    scrollBehavior () {
      return { x: 0, y: 0 }
    }
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
        if (localStorage.getItem('token') == null) {
            next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            });
        } else {
            next();
        }
    } else {
        next();
    }
})

export default router