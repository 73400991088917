<template>
  <main class="container">
    <div class="row justify-content-center">
      <b-form @submit="onSubmit" v-if="show" class="col-10">
        <p class="h3 mb-1">Редактирование: {{ model.name }}</p>
        <hr>
        <b-form-group
          id="input-group-1"
          label="Название страницы (H1):"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="model.name"
            type="text"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Идентификатор страницы:"
          label-for="alias"
        >
          <b-form-input
            id="alias"
            v-model="model.alias"
            type="text"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-1"
          label="Заголовок (title):"
          label-for="title"
        >
          <b-form-input
            id="title"
            v-model="meta.title.value"
            type="text"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Описание (description):"
          label-for="description"
        >
          <b-form-input
            id="description"
            v-model="meta.description.value"
            type="text"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Ключивые слова (keywords):"
          label-for="keywords"
        >
          <b-form-input
            id="keywords"
            v-model="meta.keywords.value"
            type="text"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Канонический адрес (canonical):"
          label-for="canonical"
        >
          <b-form-input
            id="canonical"
            v-model="meta.canonical.value"
            type="text"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Содержание страницы:"
          label-for="content"
        >
          <ckeditor id="content" v-model="model.content" :config="editorConfig"></ckeditor>
        </b-form-group>
        
        <b-form-group label="noIndex:" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="robots"
            v-model="meta.robots.value"
            :options="robots"
            :aria-describedby="ariaDescribedby"
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group label="Отображать дочерние элементы как меню:" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="insidemenu"
            v-model="props.insidemenu"
            :options="options"
            :aria-describedby="ariaDescribedby"
          ></b-form-radio-group>
        </b-form-group>

        <b-button type="submit" variant="primary" >Сохранить</b-button>
        <div class="clearfix mb-5"></div>
      </b-form>
    <div v-else class="text-center"><img src="/img/loading.gif" alt="загрузка"></div>
    </div>
    
    <b-modal v-model="modalShow" ok-only>Данные успешно сохранены.</b-modal>
  </main>
</template>
<script>
  export default {
    name: 'aboutView',
    data() {
      return {
        breadcrumb: [
          {
            html: '<i class="fa fa-home" aria-hidden="true"></i>',
            to: { name: 'profile' }
          },
          {
            text: 'Об академии',
            to: { name: 'aboutIndex' }
          },
          {
            text: 'Редактирование'
          }
        ],
        editorConfig: {
          language: 'ru',
        },
        model: [],
        props: [],
        meta: [],
        modalShow: false,
        robots: [
          { text: 'не требуется', value: '' },
          { text: 'noindex', value: 'noindex' },
          { text: 'noindex, nofollow', value: 'noindex, nofollow' }
        ],
        options: [
          { text: 'Не показывать', value: 'hidden' },
          { text: 'До основного содержания', value: 'before' },
          { text: 'После', value: 'after' }
        ],
        show: false
      }
    },
    watch: {
      '$route.params.id': {
        handler: function() {
          this.$emit('breadcrumb', this.breadcrumb)
          this.getData()
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      onSubmit: function() {
        event.preventDefault();
        this.$set(this, 'show', false)
        var node = Object.assign(this.model, {props: this.props});

        let options = {
          params: {
            alias: this.$route.params.alias,
            Node: node,
            meta: this.meta
          }
        };

        this.$http.post('/akbiz/about/update', options).then(() => {
          this.$set(this, 'modalShow', true)
          this.$set(this, 'show', true)
        });
      },
      getData: function() {
        this.$set(this, 'show', false)
        let options = {
          params: {
            alias: this.$route.params.alias
          }
        };

        this.$http.get('/akbiz/about/view', options).then((response) => {
          this.$set(this, 'model', response.data.model)
          this.$set(this, 'props', response.data.props)
          this.$set(this, 'meta', response.data.meta)
          this.$set(this, 'show', true)
        });   
      }
    }
  }
</script>
<style>

</style>