<template>
  <div>
    <div class="tags__list">
      <template v-if="tags.length">
        <tag
          v-for="(item) in tags"
          :item="item"
          :key="item.hash"
        />
      </template>

      <template v-else>
        <span>Нет меток, добавите новые</span>
      </template>
    </div>
    <tag-add />
  </div>
</template>

<script>
import tag from "@/components/tags/tag";
import tagAdd from "@/components/tags/tagAdd";

export default {
  name: 'TagsList',
  components: {
    tag,
    tagAdd
  },
  computed: {
    tags() {
      return this.$store.state.tags.list;
    }
  },
}
</script>