<template>
  <div class="toast__list">
    <toast
      v-for="item in toasts"
      :key="item.id"
      :item="item"
    />
  </div>
</template>

<script>
import toast from "@/components/toasts/toast";
export default {
  name: 'ToastsList',
  components: {
    toast
  },
  computed: {
    toasts() {
      return this.$store.state.toasts.list;
    }
  },
}
</script>