<template>
  <main class="container">
    <div class="row justify-content-center">
      <section class="about-index pb-2">
        <div v-if="showContent">
          <p class="h3 mb-1">Раздел - Об академии</p>
          <div class="overflow-auto">        
            <b-form @submit="onSubmit" v-if="showContent" class="col-md-12">
              <div class="row align-items-end">
                <div class="col-md-9"> 
                  <b-form-group
                    label="по названию:"
                    label-for="name"
                    class="mb-0"
                  >
                    <b-form-input
                      id="title"
                      v-model="search.name"
                      type="text"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-3">
                    <b-button type="submit" variant="primary" >Найти</b-button>
                </div>
              </div>

              <div class="clearfix mb-5"></div>
            </b-form>

            <b-table 
              id="data-table" 
              :items="models" 
              :per-page="perPage" 
              :current-page="1" 
              :fields="fields">
                <template v-slot:cell(editItem)="data">
                  <div v-if="data.item.editItem">
                    <router-link :to="`${data.item.editItem}`">редактировать</router-link>
                  </div>
                </template>
            </b-table>
            
            <b-pagination 
              v-model="currentPage" 
              :total-rows="totalCount" 
              :per-page="perPage" 
              aria-controls="data-table">    
            </b-pagination>
            
          </div>
        </div>
        <div v-else class="text-center"><img src="/img/loading.gif" alt="загрузка"></div>
      </section>
    </div>
  </main>
</template>

<script>
  export default {
    name: "aboutIndex",
    metaInfo: {
      title: 'Об академии',
    },
    data() {
       return {
        search: {
            name: '',
        },
        models: [],
        currentPage: 1,
        perPage: null,
        totalCount: null,
        showContent: false,
        fields: [
          { key: 'name', label: 'Название страницы' },
          { key: 'alias', label: 'Идентификатор страницы' },
          { key: 'editItem', label: '', class: 'bg-hidden text-center' }
        ],
        breadcrumb: [
          {
            html: '<i class="fa fa-home" aria-hidden="true"></i>',
            to: { name: 'profile' }
          },
          {
            text: 'Об академии',
            to: { name: 'aboutIndex' }
          }
        ]    
      }
    },
    watch: {
      'currentPage': 'getData'
    },
    mounted: function() {
      this.$emit('breadcrumb', this.breadcrumb)
      this.getData()
    },
    methods: {
      onSubmit(evt) {     
        let options = {
          params: {
            page: this.currentPage,
            name: this.search.name,
          }
        }

        this.$http.get('/akbiz/about/index', options).then((response) => {
          this.$set(this, 'models', response.data.models)
          this.$set(this, 'currentPage', response.data.currentPage)
          this.$set(this, 'perPage', response.data.perPage)
          this.$set(this, 'totalCount', response.data.totalCount)
          this.$set(this, 'showContent', true)  
        });
         evt.preventDefault();
      },
      getData: function() {
        this.$set(this, 'showContent', false)

        let options = {
          params: {
            page: this.currentPage,
            name: this.search.name
          }
        }
        
        this.$http.get('/akbiz/about/index', options).then((response) => {
          this.$set(this, 'models', response.data.models)
          this.$set(this, 'currentPage', response.data.currentPage)
          this.$set(this, 'perPage', response.data.perPage)
          this.$set(this, 'totalCount', response.data.totalCount)
          this.$set(this, 'showContent', true)  
        })   
      }
    }
  }
</script>

<style scoped>

</style>

