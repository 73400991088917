<template>
  <main class="container">
    <div class="row justify-content-center">
      <b-form @submit="onSubmit" v-if="showContent" class="col-10">
        <p class="h3">Редактирование файла sitemap</p>
        <hr class="my-1">

        <b-form-group
          label="Данные файла (sitemap):"
          label-for="sitemap"
        >
          <b-form-textarea
            id="sitemap"
            v-model="file"
            rows="3"
            max-rows="16"
          ></b-form-textarea>
        </b-form-group>
        
        <b-button type="submit" variant="primary" >Сохранить</b-button>
        <div class="clearfix mb-5"></div>
      </b-form>
      <div v-else class="text-center"><img src="/img/loading.gif" alt="загрузка"></div>
      <b-modal v-model="modalShow" ok-only>Данные успешно сохранены.</b-modal>
    </div>  
  </main>
</template>

<script>
export default {
  name: "sitemap",
  data() {
    return {
      modalShow: false,
      showContent: false,
      file: '',
      breadcrumb: [
        {
          html: '<i class="fa fa-home" aria-hidden="true"></i>',
          to: { name: 'profile' }
        },
        {
          text: 'Редактирование файла sitemap'
        }
      ]
    }
  },
  mounted: function() {
    this.$emit('breadcrumb', this.breadcrumb)
    this.getData()
  },
  methods: {
    onSubmit: function() {
      event.preventDefault();
      this.$set(this, 'showContent', false)
      
      let options = {
        params: { file: this.file }
      };

      this.$http.post('/akbiz/sitemap/update', options).then((response) => {
        if (response.data.success == 'true') {
          this.$set(this, 'modalShow', true)
        }
        
        this.$set(this, 'showContent', true)
      });
    },
    getData: function() {
      this.$set(this, 'showContent', false)
      
      this.$http.get('/akbiz/sitemap/update').then((response) => {
        this.$set(this, 'file', response.data.file)
        this.$set(this, 'showContent', true)  
      })   
    }
  }
}
</script>
