import Vue from "vue";
import Vuex from "vuex";
import toasts from "@/store/modules/toasts";
import tags from "@/store/modules/tags";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    toasts,
    tags
  }
})

export default store;