import Vue from 'vue'
import Axios from 'axios'
import App from './App.vue'
import router from './router'
import Meta from 'vue-meta'
import BootstrapVue from 'bootstrap-vue'
import CKEditor from 'ckeditor4-vue';
import store from '@/store/store';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'font-awesome/css/font-awesome.css'
import './assets/styles/app.scss'

Vue.use(CKEditor);
Vue.use(Meta);
Vue.use(BootstrapVue);
Vue.config.productionTip = false;

Vue.prototype.$http = Axios;
Vue.prototype.$http.defaults.baseURL = 'https://crm.akbiz.ru/';

if (window.location.hostname == 'localhost')
  Vue.prototype.$http.defaults.baseURL = 'http://crm.akbiz:8888/';

var token = localStorage.getItem('token');

if (token)
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
