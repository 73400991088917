
<template>
  <main class="container">
    <div class="row justify-content-center">
      <b-form @submit="onSubmit" v-if="showContent" class="col-10">
        <p class="h3">Редактирование блока</p>
        <hr class="my-1">

        <b-form-group
          label="Заголовок:"
          label-for="title"
        >
          <b-form-input
            id="title"
            v-model="model.title"
            type="text"
            required
          ></b-form-input>
        </b-form-group>
        
        <b-form-group
          label="Содержание блока:"
          label-for="content"
        >
          <ckeditor id="content" v-model="model.content" :config="editorConfig"></ckeditor>
        </b-form-group>
        
        <div class="row mt-1">
          <div class="col-md-4"> 
            <b-form-group
              label="Сортировка:"
              label-for="sort"
            >
              <b-form-input
                id="title"
                v-model="model.sort"
                type="text"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4"> 
            <b-form-group
              label="Стиль блока:"
              label-for="style"
              class="mb-0"
            >
              <b-form-select id="style" v-model="model.style" :options="styleOptions"></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
              label="Тип блока:"
              label-for="type"
              class="mb-0"
            >
              <b-form-select id="type" v-model="model.type" :options="typeOptions"></b-form-select>
            </b-form-group>
          </div>
        </div>
        
        <div class="row mt-1">
          <div class="col-md-6"> 
            <b-form-group label="Показывать блок на лендингах:" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="landing"
                v-model="model.landing"
                :options="statusOptions"
                :aria-describedby="ariaDescribedby"
              ></b-form-radio-group>
            </b-form-group>
          </div>

          <div class="col-md-6"> 
            <b-form-group label="Показывать блок на основном сайте:" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="promo"
                v-model="model.promo"
                :options="statusOptions"
                :aria-describedby="ariaDescribedby"
              ></b-form-radio-group>
            </b-form-group>
          </div>
        </div>

        <b-button type="submit" variant="primary" >Сохранить</b-button>
        <div class="clearfix mb-5"></div>
      </b-form>
      <div v-else class="text-center"><img src="/img/loading.gif" alt="загрузка"></div>
      <b-modal v-model="modalShow" ok-only>Данные успешно сохранены.</b-modal>
    </div>  
  </main>
</template>

<script>
export default {
  name: "blockEdit",
  data() {
    return {
      modalShow: false,
      showContent: false,
      model: [],
      typeOptions: [],
      styleOptions:[
        { value: '', text: 'Нет стиля' },
        { value: 'cornflowerblue', text: 'Голубой' },
        { value: 'antiquewhite', text: 'Антично-белый' },
        { value: 'obuchenie-section', text: 'Плитки' },
        { value: 'galochka', text: 'Галочки' },
        { value: 'paragraf', text: 'Параграфы' },
        { value: 'tochka', text: 'Точки' },
        { value: 'book', text: 'Книги' },
        { value: 'no-link', text: 'Без ссылок' }
      ],
      statusOptions: [
        { text: 'Не показывать', value: 0 },
        { text: 'Показывать', value: 1 }
      ],
      editorConfig: {
          language: 'ru',
      },
      breadcrumb: [
        {
          html: '<i class="fa fa-home" aria-hidden="true"></i>',
          to: { name: 'profile' }
        },
        {
          text: 'Редактирование'
        }
      ]
    }
  },
  mounted: function() {
    this.$emit('breadcrumb', this.breadcrumb);
    this.getData();
  },
  methods: {
    onSubmit: function() {
      event.preventDefault();
      this.$set(this, 'showContent', false);
      
      this.$http.post('/akbiz/obuchenie/block-edit?id='+ this.$route.params.id, {
        model: this.model
      }).then((res) => {
        if (res.data.redirect !== null) {
          this.$router.push(res.data.redirect);
        }
      });
    },
    getData: function() {
      this.$set(this, 'showContent', false);

      this.$http.get('/akbiz/obuchenie/block-type-options').then((response) => {
        this.$set(this, 'typeOptions', response.data)
      });

      this.$http.get('/akbiz/obuchenie/block-edit?id='+ this.$route.params.id)
        .then((response) => {
          this.$set(this, 'model', response.data.model)
          this.$set(this, 'showContent', true)  
      })   
    }
  }
}
</script>
