<template>
  <div class="toast__item" :class="style">
    <div class="toast__close" @click="closeHandler">
      <img :src="close">
    </div>
    <div class="toast__title" v-if="item.title">{{ item.title }}</div>
    <div class="toast__message">{{ item.message }}</div>
  </div>
</template>

<script>
import close from '@/assets/images/close.svg';

export default {
  name: 'Toast',
  props: {
    item: Object,
  },
  data() {
    return {
      close: close,
      timeoutId: null,
    }
  },
  computed: {
    style() {
      return `toast__item_${this.item.type}`;
    }
  },
  methods: {
    closeHandler() {
      clearTimeout(this.timeoutId);
      this.$store.commit('deleteToast', this.item.id);
    }
  },
  mounted() {
    this.timeoutId = setTimeout(this.closeHandler, 5000);
  }
}
</script>