<template>
  <main class="container">
    <div class="row justify-content-center">
      <section class="col-10 pt-3 pb-2">
        <p class="h3">Личная информация и контакты</p>
        <hr class="my-1">
        <p>Здесь вы можете изменить свои личные данные</p>
      </section>
    </div>  
  </main>
</template>

<script>
export default {
  name: "profile",
  data() {
    return {
      breadcrumb: [
        {
          html: '<i class="fa fa-home" aria-hidden="true"></i>'
        },
        {
          text: 'Личная информация',
          to: { name: 'profile' }
        }
      ]
    }
  },
  created() {
    this.$emit('breadcrumb', this.breadcrumb)
    this.$emit('loggedIn')
  }
}
</script>
