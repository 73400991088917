<template>
  <main class="container">
    <div class="row justify-content-center">
      <b-form @submit="onSubmit" v-if="show" class="col-10">
        <p class="h3 mb-1">Редактирование: {{ model.name }}</p>
        <hr>
        <b-form-group
          label="Название страницы (H1):"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="model.name"
            type="text"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-1"
          label="Заголовок (title):"
          label-for="title"
        >
          <b-form-input
            id="title"
            v-model="meta.title.value"
            type="text"
            required
          ></b-form-input>
        </b-form-group>

        
        <div class="row align-items-end mb-2">
          <div class="col-md-9">
            <b-form-group
              label="Tеги:"
              label-for="tags"
            >
              <b-form-input
                id="tags"
                v-model="model.tags"
                type="text"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group
              label="Время чтения:"
              label-for="timeRead"
            >
              <b-form-input
                id="timeRead"
                v-model="props.time_read"
                type="text"
                required
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row align-items-end mb-2">
          <div class="col-md-9">    
            <b-form-group
              label="Раздел:"
              label-for="category"
              class="mb-0"
            >
              <b-form-select id="category" v-model="props.category" :options="categories"></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-3 text-right">
            <b-button type="button" variant="primary">Создать категорию</b-button>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col-md-4"> 
            <b-form-group
              label="Идентификатор страницы:"
              label-for="alias"
            >
              <b-form-input
                id="alias"
                v-model="model.alias"
                type="text"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3"> 
            
            <b-form-group
              label="Автор статьи:"
              label-for="author"
            >
              <b-form-input
                id="author"
                v-model="model.author"
                type="text"
                required
              ></b-form-input>
            </b-form-group>

          </div>
          <div class="col-md-5"> 
             <b-form-group
              label="Дата:"
              label-for="datepicker"
            >
              <b-form-datepicker locale="ru" id="datepicker" v-model="model.date" class="mb-2"></b-form-datepicker>
            </b-form-group>
          </div>
        </div>

        <b-form-group
          label="Описание (description):"
          label-for="description"
        >
          <b-form-input
            id="description"
            v-model="meta.description.value"
            type="text"
            required
          ></b-form-input>
        </b-form-group>

        <div class="row mt-1">
          <div class="col-md-6">     
            <b-form-group
              label="Ключивые слова (keywords):"
              label-for="keywords"
            >
              <b-form-input
                id="keywords"
                v-model="meta.keywords.value"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6"> 
            <b-form-group
              label="Канонический адрес (canonical):"
              label-for="canonical"
            >
              <b-form-input
                id="canonical"
                v-model="meta.canonical.value"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col-md-6"> 
            <b-form-group 
              label="Превью:"
              label-for="file"
            >
              <b-form-file v-model="file" accept=".jpg, .png" id="file"></b-form-file>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Альтернативный текст превью:"
              label-for="alt"
            >
              <b-form-input
                id="alt"
                v-model="props.file_alt"
                type="text"
              ></b-form-input>
            </b-form-group>  
          </div>
        </div>

        <b-form-group
          label="Анонс:"
          label-for="anons"
        >
          <b-form-input
            id="anons"
            v-model="model.anons"
            type="text"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Содержание страницы:"
          label-for="content"
        >
          <ckeditor id="content" v-model="model.content" :config="editorConfig"></ckeditor>
        </b-form-group>
        
        <b-form-group label="noIndex:" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="robots"
            v-model="meta.robots.value"
            :options="robots"
            :aria-describedby="ariaDescribedby"
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group label="Показывать форму коментариев:" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="insidemenu"
            v-model="props.vk_comments"
            :options="vkCommentsOptions"
            :aria-describedby="ariaDescribedby"
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group
          label="Какие программы отображать на странице:"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox-group
            v-model="props.similar"
            :options="obuchenieList"
            :aria-describedby="ariaDescribedby"
            stacked
          ></b-form-checkbox-group>
        </b-form-group>

        <b-form-group
          label="Читайте также:"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox-group
            v-model="props.similar_article"
            :options="publicationList"
            :aria-describedby="ariaDescribedby"
            stacked
          ></b-form-checkbox-group>
        </b-form-group>

        <b-button type="submit" variant="primary" >Сохранить</b-button>
        <div class="clearfix mb-5"></div>
      </b-form>
    <div v-else class="text-center"><img src="/img/loading.gif" alt="загрузка"></div>
    </div>
    
    <b-modal v-model="modalShow" ok-only>Данные успешно сохранены.</b-modal>
  </main>
</template>
<script>
  export default {
    name: 'articleView',
    data() {
      return {
        breadcrumb: [
          {
            html: '<i class="fa fa-home" aria-hidden="true"></i>',
            to: { name: 'profile' }
          },
          {
            text: 'Статьи',
            to: { name: 'articleIndex' }
          },
          {
            text: 'Редактирование'
          }
        ],
        editorConfig: {
          language: 'ru',
        },
        categories: [],
        model: [],
        props: [],
        meta: [],
        file: null,
        modalShow: false,
        obuchenieList: [],
        publicationList: [],
        robots: [
          { text: 'не требуется', value: '' },
          { text: 'noindex', value: 'noindex' },
          { text: 'noindex, nofollow', value: 'noindex, nofollow' }
        ],
        vkCommentsOptions: [
          { text: 'Не показывать', value: '0' },
          { text: 'Показывать', value: '1' }
        ],
        show: false
      }
    },
    watch: {
      '$route.params.id': {
        handler: function() {
          this.$emit('breadcrumb', this.breadcrumb);
          this.getData();
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      onSubmit: function() {
        event.preventDefault();
        this.$set(this, 'show', false);
        var node = Object.assign(this.model, {props: this.props});
        let formData = new FormData();
        formData.append('file', this.file);

        let params = {
          alias: this.$route.params.alias,
          Node: node,
          meta: this.meta
        };

        formData.append('params', JSON.stringify(params));

        let config = { headers: { "Content-Type": "multipart/form-data" } };

        this.$http.post('/akbiz/publication/update', formData, config).then(() => {
          this.$set(this, 'modalShow', true);
          this.$set(this, 'show', true);
        });
      },
      getData: function() {
        this.$set(this, 'show', false);
        this.$http.get('/akbiz/category-publication/index').then((response) => {
          this.$set(this, 'categories', response.data.categories);
        });

        this.$http.get('/akbiz/obuchenie/list').then((response) => {
          this.$set(this, 'obuchenieList', response.data.obuchenieList);
        });

        this.$http.get('/akbiz/publication/list').then((response) => {
          this.$set(this, 'publicationList', response.data.publicationList);
        });

        let options = { params: { alias: this.$route.params.alias } };

        this.$http.get('/akbiz/publication/view', options).then((response) => {
          this.$set(this, 'model', response.data.model);
          this.$set(this, 'props', response.data.props);
          this.$set(this, 'meta', response.data.meta);
          this.$set(this, 'show', true);
        });   
      }
    }
  }
</script>
<style>

</style>