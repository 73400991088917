import {randString} from "@/helpers/random";

const toasts = {
  state:() => ({
    list: []
  }),
  mutations: {
    deleteToast(state, id) {
      state.list = state.list.filter(item => item.id !== id);
    },
    addToast(state, data) {
      const toast = {
        id: randString(),
        title: data.title || 'Оповещение',
        message: data.message,
        type: data.type || 'primary'
      }

      state.list.unshift(toast);
    }
  }
}

export default toasts;