<template>
  <div class="tag__add mt-3">
    <b-form-input placeholder="Название метки" v-model="tag.title"/>

    <tag-colors v-model="tag.color"/>

    <button class="btn btn-primary" @click.prevent="addTag">Добавить</button>
  </div>
</template>

<script>
import tagColors from "@/components/tags/tagColors";

export const colorsMap = {
  RED: '#FFADAD',
  BLUE: '#A4BFF4',
  ORANGE: '#FFCC66',
  DARK_GREY: '#C4C0C0',
  LIGHT_GREEN: '#D5EB84',
  VIOLET: '#C999FF',
  GREEN: '#AFE9C9',
  GRAY: '#BCC0C8',
  BROWN: '#DBBF95',
}

export default {
  name: 'TagColors',
  components: {
    tagColors
  },
  data() {
    return {
      tag: {
        color: colorsMap.RED,
        title: ''
      },
    }
  },
  methods: {
    addTag() {
      this.$store.commit('addTag', this.tag);

      this.tag = {
        color: colorsMap.RED,
        title: ''
      }
    }
  }
}
</script>