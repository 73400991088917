<template>
  <div id="app">
    <header>
      <b-navbar toggleable="lg" type="dark" variant="info" class="px-1">
        <div class="container">
          <b-navbar-brand :active="isHome"  to="/" class="navbar-item home-item">Панель управления</b-navbar-brand>
          <b-navbar-toggle target="header-menu"></b-navbar-toggle>
          <b-collapse  id="header-menu" is-nav right>
            <b-navbar-nav v-if="isLoggedIn" class="ml-auto">
              <b-nav-item active-class="active" to="/about" class="navbar-item">Об Академии</b-nav-item>
              <b-nav-item active-class="active" to="/course" class="navbar-item">Программы обучения</b-nav-item>
              <b-nav-item active-class="active" to="/article" class="navbar-item">Статьи</b-nav-item>
              <b-nav-item active-class="active" to="/hub-pages" class="navbar-item">Хабовые страницы</b-nav-item>
              <b-nav-item active-class="active" to="/robots" class="navbar-item">файл robots</b-nav-item>
              <b-nav-item active-class="active" to="/sitemap" class="navbar-item">файл sitemap</b-nav-item>
              <button @click="signOut" class="btn btn-secondary px-2 pb-2">Выход</button>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
      <div class="col">
        <div class="row breadcrumb-block d-none d-sm-block">
            <b-breadcrumb :items="breadcrumb" v-if="breadcrumb"></b-breadcrumb>
        </div>
      </div>
    </header>

    <router-view @loggedIn="loggedIn" @breadcrumb="getBreadcrumb"></router-view>

    <toasts-list />
  </div>
</template>

<script>
import toastsList from "@/components/toasts/toastsList";
export default {
  name: 'app',
  components: {
    toastsList
  },
  data() {
    return {
      breadcrumb: false,
      login: false
    }
  },
  methods: {
    getBreadcrumb(data) {
      this.$set(this, 'breadcrumb', data)
    },
    loggedIn() {
      if (localStorage.getItem('token') !== null)
        this.$set(this, 'login', true)
    },
    signOut(evt) {
      evt.preventDefault();
    
      this.$http.get('/akbiz/security/sign-out').then(function (response) {
        if (!response.data.error) {
          localStorage.removeItem('token')
          this.$router.go('/login')
        } 
      }.bind(this))
    }
  },
  computed: {
    isHome: function() {
      if (this.$route.name == 'home')
        return true
      
      return false
    },
    isLoggedIn : function() {
      return this.login;
    }
  }
}
</script>
<style scoped>

</style>