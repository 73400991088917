<template>
  <div class="tag"
       draggable="true"
       @dragstart="dragStartHandler"
       @dragend="dragEndHandler"
       @dragenter="dragEnterHandler"
       @dragleave="dragLeaveHandler"
       @dragover.prevent
       @drop="dropHandler"

  >
    <div class="tag__color" :style="{backgroundColor: item.color}"></div>
    <div class="tag__title">{{ item.title }}</div>
    <div class="tag__close">
      <img :src="close" @click="deleteHandler">
    </div>
  </div>
</template>

<script>
import close from "@/assets/images/close.svg";

export default {
  name: 'Tags',
  data() {
    return {
      close
    }
  },
  props: {
    item: Object
  },
  methods: {
    async deleteHandler() {
      if (this.item.id) {
        const option = {id: this.item.id}
        await this.$http.post('/akbiz/obuchenie/delete-tag', option);
      }

      this.$store.commit('deleteTag', this.item.hash);
    },
    dragStartHandler(e) {
      if (e.dataTransfer) {
        e.dataTransfer.setData('tagHash', this.item.hash);
      }

      e.currentTarget.classList.add('tag_drag');
    },
    dragEndHandler(e) {
      e.currentTarget.classList.remove('tag_drag');
    },
    dragEnterHandler(e) {
      e.currentTarget.classList.add('tag_enter');
    },
    dragLeaveHandler(e) {
      e.currentTarget.classList.remove('tag_enter');
    },
    dropHandler(e) {
      if (e.dataTransfer) {
        const donor = e.dataTransfer.getData('tagHash');
        const recipient = this.item.hash;

        this.$store.commit('sortTag', {donor, recipient})
      }

      e.currentTarget.classList.remove('tag_enter');

    }
  }
}
</script>