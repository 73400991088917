<template>
  <main class="container">
    <div class="row justify-content-center">
      <b-form @submit.prevent="onSubmit" v-if="show" class="col-10">
        <p class="h3 mb-1">Редактирование: {{ model.name }}</p>
        <hr>
        <b-form-group
          label="Полное название (H1):"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="model.name"
            type="text"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-1"
          label="Заголовок (title):"
          label-for="title"
        >
          <b-form-input
            id="title"
            v-model="meta.title.value"
            type="text"
            required
          ></b-form-input>
        </b-form-group>

        <div class="row align-items-end mb-2">
          <div class="col-md-6">

            <b-form-group
              label="Тип программы  (H1):"
              label-for="name_first"
            >
              <b-form-input
                id="name_first"
                v-model="model.name_first"
                type="text"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Название программы:"
              label-for="name_second"
            >
              <b-form-input
                id="name_second"
                v-model="model.name_second"
                type="text"
                required
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col-md-9">
            <b-form-group
              label="Идентификатор страницы:"
              label-for="alias"
            >
              <b-form-input
                id="alias"
                v-model="model.alias"
                type="text"
                required
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group
              label="Сортировка:"
              label-for="sort"
            >
              <b-form-input
                id="sort"
                v-model="model.sort"
                type="text"
                required
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <b-form-group
          label="Описание (description):"
          label-for="description"
        >
          <b-form-textarea
            id="description"
            v-model="meta.description.value"
            type="text"
            rows="4"
            required
          ></b-form-textarea>
        </b-form-group>

        <div class="row mt-1">
          <div class="col-md-6">
            <b-form-group
              label="Ключивые слова (keywords):"
              label-for="keywords"
            >
              <b-form-input
                id="keywords"
                v-model="meta.keywords.value"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Канонический адрес (canonical):"
              label-for="canonical"
            >
              <b-form-input
                id="canonical"
                v-model="meta.canonical.value"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <b-form-group label="noIndex:" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="robots"
            v-model="meta.robots.value"
            :options="robots"
            :aria-describedby="ariaDescribedby"
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group label="Статус:" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="insidemenu"
            v-model="model.status"
            :options="statusOptions"
            :aria-describedby="ariaDescribedby"
          ></b-form-radio-group>
        </b-form-group>

        <hr>

        <label>Метки с особенностями <small>(для прайс-листа основной цвет береться по первой метке)</small></label>
        <tags-list />

        <hr>

        <div v-if="obuchenieList">
          <b-form-group
            v-slot="{ ariaDescribedby }"
          >
            <label>
              Слушатели, которые искали эту программу, также интересовались:
              <a href="#" @click.prevent v-b-toggle.collapse-list>(развернуть)</a>
            </label>
            <b-collapse id="collapse-list">
              <b-form-checkbox-group
                v-model="similar"
                :options="obuchenieList"
                :aria-describedby="ariaDescribedby"
                stacked
              ></b-form-checkbox-group>
            </b-collapse>
          </b-form-group>
        </div>

        <b-button type="submit" variant="primary" >Сохранить</b-button>
        <div class="clearfix mb-5"></div>
      </b-form>

      <div v-else class="text-center"><img src="/img/loading.gif" alt="загрузка"></div>
    </div>
  </main>
</template>

<script>
import tagsList from "@/components/tags/tagsList";

export default {
  name: 'courseView',
  components: {
    tagsList
  },
  data() {
    return {
      breadcrumb: [
        {
          html: '<i class="fa fa-home" aria-hidden="true"></i>',
          to: {name: 'profile'}
        },
        {
          text: 'Обучение',
          to: {name: 'courseIndex'}
        },
        {
          text: 'Редактирование'
        }
      ],
      robots: [
        { text: 'не требуется', value: '' },
        { text: 'noindex', value: 'noindex' },
        { text: 'noindex, nofollow', value: 'noindex, nofollow' }
      ],
      statusOptions: [
        { text: 'Не опубликовано', value: '0' },
        { text: 'Опубликовано', value: '1' },
        { text: 'Страница отключена и не доступна (404)', value: '99' }
      ],
      show: false,
      model: null,
      meta: null,
      similar: [],
      obuchenieList: null
    }
  },
  methods: {
    onSubmit() {
      this.show = false;

      const options = {
        params: {
          model: this.model,
          meta: this.meta,
          similar: this.similar,
          tags: this.$store.state.tags.list
        }
      };


      this.$http.post('/akbiz/obuchenie/update', options)
        .then((response) => {
          this.model = response.data.model;
          this.meta = response.data.meta;

          this.$store.commit('addTags', response.data.tags);

          this.show = true;
          this.$store.commit('addToast', {message: 'Данные сохранены'});
        })
    },
    getData() {
      const option = {params: {id: this.$route.params.id}}
      this.$http.get('/akbiz/obuchenie/view', option)
        .then((response) => {
          this.show = true;
          this.model = response.data.model;
          this.meta = response.data.meta;
          this.similar = response.data.similar;

          this.$store.commit('addTags', response.data.tags);
        });
    },
    getList() {
      this.$http.get('/akbiz/obuchenie/list')
        .then((response) => {
          this.obuchenieList = response.data.obuchenieList;
        });
    }
  },
  mounted() {
    this.getData();
    this.getList();
    this.$emit('breadcrumb', this.breadcrumb);
  },
}
</script>